export default [
  {
    bbox: [
      6.78876257185905,
      -8.238832479875764,
      9.842069017474145,
      -3.288872105333856,
    ],
    name: "Alabama",
    fips: "01",
    abbr: "AL",
    short: "Ala.",
  },
  {
    bbox: [
      -24.027421939301178,
      -13.077238681532382,
      -12.541538846875941,
      -7.012250212164434,
    ],
    name: "Alaska",
    fips: "02",
    abbr: "AK",
    short: "Alaska",
  },
  {
    bbox: [
      -15.213475143485383,
      -6.651259222260148,
      -9.822358383393697,
      -0.3660023547903866,
    ],
    name: "Arizona",
    fips: "04",
    abbr: "AZ",
    short: "Ariz.",
  },
  {
    bbox: [
      1.5642308321946754,
      -5.706016084016094,
      5.561618803059807,
      -2.0813261576661506,
    ],
    name: "Arkansas",
    fips: "05",
    abbr: "AR",
    short: "Ark.",
  },
  {
    bbox: [
      -20.67127325132435,
      -4.500457828815312,
      -14.312236559802379,
      6.379574430070958,
    ],
    name: "California",
    fips: "06",
    abbr: "CA",
    short: "Calif.",
  },
  {
    bbox: [
      -9.822358383393697,
      -1.573089307894275,
      -4.062582575529444,
      2.9884736360049957,
    ],
    name: "Colorado",
    fips: "08",
    abbr: "CO",
    short: "Colo.",
  },
  {
    bbox: [
      16.908785599459225,
      4.257582451895138,
      18.27782184094931,
      5.602377394830399,
    ],
    name: "Connecticut",
    fips: "09",
    abbr: "CT",
    short: "Conn.",
  },
  {
    bbox: [
      15.749965176139472,
      1.4318920782113793,
      16.581018014844087,
      2.8173388575131626,
    ],
    name: "Delaware",
    fips: "10",
    abbr: "DE",
    short: "Del.",
  },
  {
    bbox: [
      14.913897606470508,
      1.5460337201770358,
      15.081108810526711,
      1.7470072535561632,
    ],
    name: "DistrictofColumbiaFederaldistrict",
    fips: "11",
    abbr: "DC",
    short: "D.C.",
  },
  {
    bbox: [
      7.614689953944909,
      -13.16062104865046,
      14.833996952143568,
      -7.0202643780450895,
    ],
    name: "Florida",
    fips: "12",
    abbr: "FL",
    short: "Fla.",
  },
  {
    bbox: [
      8.891330600150715,
      -7.517270029318274,
      13.203573190965047,
      -3.0254804968726186,
    ],
    name: "Georgia",
    fips: "13",
    abbr: "GA",
    short: "Ga.",
  },
  {
    bbox: [
      -12.235675032772019,
      -13.033361105959122,
      -6.841917079463666,
      -9.787130053352268,
    ],
    name: "Hawaii",
    fips: "15",
    abbr: "HI",
    short: "Hawaii",
  },
  {
    bbox: [
      -14.924866009663509,
      4.202332017417153,
      -10.202187347944076,
      11.776285391656351,
    ],
    name: "Idaho",
    fips: "16",
    abbr: "ID",
    short: "Idaho",
  },
  {
    bbox: [
      3.859942692664658,
      -1.5538024155213153,
      7.023901597196179,
      4.0787340475399105,
    ],
    name: "Illinois",
    fips: "17",
    abbr: "IL",
    short: "Ill.",
  },
  {
    bbox: [
      6.631139715194884,
      -0.6722834612919297,
      9.091253295025538,
      3.5710778992067027,
    ],
    name: "Indiana",
    fips: "18",
    abbr: "IN",
    short: "Ind.",
  },
  {
    bbox: [
      -0.003287962503122322,
      1.7713602007047815,
      4.7847787842588385,
      4.917702140209903,
    ],
    name: "Iowa",
    fips: "19",
    abbr: "IA",
    short: "Iowa",
  },
  {
    bbox: [
      -4.312581802076493,
      -1.7189991772173834,
      1.5568623084378732,
      1.4600188362314426,
    ],
    name: "Kansas",
    fips: "20",
    abbr: "KS",
    short: "Kans.",
  },
  {
    bbox: [
      5.57511837393011,
      -2.049767202324225,
      11.444004139751302,
      0.9618254643717911,
    ],
    name: "Kentucky",
    fips: "21",
    abbr: "KY",
    short: "Ky.",
  },
  {
    bbox: [
      2.0942305385284086,
      -9.58977711716737,
      6.679173692355426,
      -5.63593105240087,
    ],
    name: "Louisiana",
    fips: "22",
    abbr: "LA",
    short: "La.",
  },
  {
    bbox: [
      17.77873372349024,
      6.748839377867429,
      20.715666482029366,
      11.360033594083337,
    ],
    name: "Maine",
    fips: "23",
    abbr: "ME",
    short: "Maine",
  },
  {
    bbox: [
      12.992170407141359,
      0.8593018227770233,
      16.58524691293735,
      2.6610401897363487,
    ],
    name: "Maryland",
    fips: "24",
    abbr: "MD",
    short: "Md.",
  },
  {
    bbox: [
      16.885210802153335,
      5.039103151490234,
      19.63903752247914,
      6.607362705289028,
    ],
    name: "Massachusetts",
    fips: "25",
    abbr: "MA",
    short: "Mass.",
  },
  {
    bbox: [
      4.311393207392887,
      3.4115212091676272,
      10.314803042365645,
      9.728517198604502,
    ],
    name: "Michigan",
    fips: "26",
    abbr: "MI",
    short: "Mich.",
  },
  {
    bbox: [
      -0.3598399894688226,
      4.830655657031823,
      4.851131410027055,
      10.64130302549951,
    ],
    name: "Minnesota",
    fips: "27",
    abbr: "MN",
    short: "Minn.",
  },
  {
    bbox: [
      4.163906983897268,
      -8.365237566297376,
      7.011217610648325,
      -3.47898780236245,
    ],
    name: "Mississippi",
    fips: "28",
    abbr: "MS",
    short: "Miss.",
  },
  {
    bbox: [
      0.6333897456991621,
      -2.5978193596598005,
      5.92061883489705,
      2.0017880294182087,
    ],
    name: "Missouri",
    fips: "29",
    abbr: "MO",
    short: "Mo.",
  },
  {
    bbox: [
      -12.96826225453144,
      6.564602140243317,
      -4.876615406777649,
      11.640698766085906,
    ],
    name: "Montana",
    fips: "30",
    abbr: "MT",
    short: "Mont.",
  },
  {
    bbox: [
      -5.556656276362044,
      1.3112578289151466,
      0.9888107714578809,
      4.577472384520447,
    ],
    name: "Nebraska",
    fips: "31",
    abbr: "NE",
    short: "Nebr.",
  },
  {
    bbox: [
      -17.814662675113652,
      -2.255327448997131,
      -12.770078413259162,
      5.537740662565104,
    ],
    name: "Nevada",
    fips: "32",
    abbr: "NV",
    short: "Nev.",
  },
  {
    bbox: [
      17.324567713044228,
      6.165109858400001,
      18.716658644991426,
      8.893448956245129,
    ],
    name: "NewHampshire",
    fips: "33",
    abbr: "NH",
    short: "N.H.",
  },
  {
    bbox: [
      15.940510946179653,
      2.0179046437565025,
      17.001467680839614,
      4.446304334965416,
    ],
    name: "NewJersey",
    fips: "34",
    abbr: "NJ",
    short: "N.J.",
  },
  {
    bbox: [
      -10.60748420713494,
      -6.744606697737549,
      -5.0697506991274475,
      -1.011957822753802,
    ],
    name: "NewMexico",
    fips: "35",
    abbr: "NM",
    short: "N.M.",
  },
  {
    bbox: [
      12.341078760486402,
      3.6773147280912064,
      18.323352680510943,
      8.221168208643876,
    ],
    name: "NewYork",
    fips: "36",
    abbr: "NY",
    short: "N.Y.",
  },
  {
    bbox: [
      -5.131026307366341,
      7.257937845751426,
      0.07848740325580389,
      10.488323419867982,
    ],
    name: "NorthDakota",
    fips: "38",
    abbr: "ND",
    short: "N.D.",
  },
  {
    bbox: [
      8.740484202063755,
      0.4357359903069367,
      12.058926406984698,
      4.229419903329585,
    ],
    name: "Ohio",
    fips: "39",
    abbr: "OH",
    short: "Ohio",
  },
  {
    bbox: [
      -5.107072791248316,
      -5.1153763850433425,
      1.73176785121042,
      -1.5138966200683646,
    ],
    name: "Oklahoma",
    fips: "40",
    abbr: "OK",
    short: "Okla.",
  },
  {
    bbox: [
      -20.11854327598457,
      5.029971283019043,
      -13.744026226776297,
      10.415610798616157,
    ],
    name: "Oregon",
    fips: "41",
    abbr: "OR",
    short: "Ore.",
  },
  {
    bbox: [
      11.839788730114716,
      1.9879362666193952,
      16.44749823914573,
      4.9678731284100435,
    ],
    name: "Pennsylvania",
    fips: "42",
    abbr: "PA",
    short: "Penn.",
  },
  {
    bbox: [
      18.126627210941738,
      4.788772463242687,
      18.743876597102982,
      5.675926348021256,
    ],
    name: "RhodeIsland",
    fips: "44",
    abbr: "RI",
    short: "R.I.",
  },
  {
    bbox: [
      10.741508569020581,
      -5.702606118514631,
      14.774948265541267,
      -2.6324936748130954,
    ],
    name: "SouthCarolina",
    fips: "45",
    abbr: "SC",
    short: "S.C.",
  },
  {
    bbox: [
      -5.386107271614395,
      3.808361563584315,
      0.14677163420257971,
      7.501735890806595,
    ],
    name: "SouthDakota",
    fips: "46",
    abbr: "SD",
    short: "S.D.",
  },
  {
    bbox: [
      5.081919192173248,
      -3.6001801762148453,
      11.83241059123214,
      -1.24976666074609,
    ],
    name: "Tennessee",
    fips: "47",
    abbr: "TN",
    short: "Tenn.",
  },
  {
    bbox: [
      -8.513974073403688,
      -12.792566677538831,
      2.591931051984777,
      -2.0144223968881176,
    ],
    name: "Texas",
    fips: "48",
    abbr: "TX",
    short: "Tex.",
  },
  {
    bbox: [
      -13.727936259334687,
      -1.011957822753802,
      -9.27327646562325,
      4.576176517171882,
    ],
    name: "Utah",
    fips: "49",
    abbr: "UT",
    short: "Utah",
  },
  {
    bbox: [
      16.30741202987696,
      6.040627720100942,
      17.65560019905834,
      8.546282502853757,
    ],
    name: "Vermont",
    fips: "50",
    abbr: "VT",
    short: "Vt.",
  },
  {
    bbox: [
      10.23691715309545,
      -1.4864511588186151,
      16.527459782628746,
      2.0216390984778037,
    ],
    name: "Virginia",
    fips: "51",
    abbr: "VA",
    short: "Va.",
  },
  {
    bbox: [
      -18.706612044482437,
      8.89508630671173,
      -13.3943643047491,
      12.76365957898321,
    ],
    name: "Washington",
    fips: "53",
    abbr: "WA",
    short: "Wash.",
  },
  {
    bbox: [
      10.827749349230933,
      -0.6650750941649148,
      14.385953816130288,
      2.9005546958527706,
    ],
    name: "WestVirginia",
    fips: "54",
    abbr: "WV",
    short: "W.Va.",
  },
  {
    bbox: [
      2.6400798319524323,
      3.9467531955492743,
      6.9072150659331175,
      8.4965044402142,
    ],
    name: "Wisconsin",
    fips: "55",
    abbr: "WI",
    short: "Wis.",
  },
  {
    bbox: [
      -10.751826998108205,
      2.57539967239831,
      -5.219909595210265,
      7.163371843504738,
    ],
    name: "Wyoming",
    fips: "56",
    abbr: "WY",
    short: "Wyo.",
  },
  {
    bbox: [
      9.922448870475563,
      -3.573407345890047,
      16.915306171070362,
      -0.481195075053267,
    ],
    name: "NorthCarolina",
    fips: "37",
    abbr: "NC",
    short: "N.C.",
  },
];
